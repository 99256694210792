<template>
    <div>
        <div class="p-content">
            <img class="p-bg" src="/assets/images/gr-bg.jpg" alt="">
            <div class="pit p-id">{{ itemData.id }}</div>
            <div class="pit p-name">{{ member.name }}</div>
            <div class="pit p-gender">{{ member.gender === 1 ? '男' : '女' }}</div>
            <div class="pit p-age">{{ report.age }}</div>
            <div class="pit p-mid">{{ member.member_id }}</div>
            <template v-if="report.member_image">
                <div class="pit p-city">
                    {{ report.member_image.area.split('-')[1] }}
                </div>
                <div class="pit p-jobs">{{ report.member_image.jobs.substring(0, 7) }}</div>
            </template>
            <div class="pit p-style" v-if="report.style">
                {{ report.style.name.replace('style', '') }}
            </div>

            <div class="pit p-color">
                {{ reportDetail.color }}
            </div>

            <div class="pit p-image" :style="`background-image:url(${report.photo})`"></div>

            <div class="pit p-height">{{ report.height }}</div>
            <div class="pit p-weight">{{ report.weight }}</div>

            <div class="pit p-grsq">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入个人诉求"
                            @focus="showRemind('gr_sq')"
                            :maxLength="130" v-model="itemData.gr_sq"></a-textarea>
            </div>
            <div class="pit p-ztfg">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入整体风格"
                            @focus="showRemind('zt_fg')"
                            :maxLength="130" v-model="itemData.zt_fg"></a-textarea>
            </div>
            <div class="pit p-ztsc">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入个整体色彩"
                            @focus="showRemind('zt_sc')"
                            :maxLength="130" v-model="itemData.zt_sc"></a-textarea>
            </div>
            <div class="pit p-sctx">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入身材体型"
                            @focus="showRemind('sc_tx')"
                            :maxLength="130" v-model="itemData.sc_tx"></a-textarea>
            </div>
            <div class="pit p-zrpf">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入妆容皮肤"
                            @focus="showRemind('zr_pf')"
                            :maxLength="54" v-model="itemData.zr_pf"></a-textarea>
            </div>
            <div class="pit p-fxfs">
                <a-textarea class="p-textarea" :maxLength="54"
                            placeholder="在这里输入发型发色"
                            @focus="showRemind('fx_ys')"
                            v-model="itemData.fx_ys"></a-textarea>
            </div>

            <div class="pit p-res">
                <a-textarea class="p-textarea"
                            placeholder="在这里输入初诊结论"
                            @focus="showRemind('result')"
                            :maxLength="130" v-model="itemData.result"></a-textarea>
            </div>

            <div class="pit p-fs">{{ reportDetail.mb_ln }}</div>
            <div class="pit p-mb-md">{{ reportDetail.mb_md }}</div>
            <div class="pit p-mb-cd">{{ reportDetail.mb_cd }}</div>
            <div class="pit p-fz-lg">{{ reportDetail.fz_lg }}</div>
            <div class="pit p-mb-lk">{{ reportDetail.mb_lk }}</div>
            <div class="pit p-mb-xt">{{ reportDetail.mb_xt }}</div>

            <div class="p-check-man">
                <a-input class="p-input mb-2" v-model="itemData.result_man" addonBefore="诊断人"/>
                <a-input class="p-input mb-10" v-model="itemData.recheck_man" addonBefore="复核人"/>

                <button class="btn" @click="saveReport">提交诊断书</button>
            </div>

        </div>

        <div class="remind">
            <button class="btn">{{ remindTitle || '常用短语' }}</button>
            <button class="btn" @click="activeRemindModal">添加</button>
            <button class="btn pull-right" @click="activeLifePhotosModal">生活照</button>

            <div v-if="remindList.length">

                <template v-for="(item,ik) in remindList">

                    <div class="remind-item" :key="ik">

                        {{ item.value }}


                        <div class="re-btn-group">
                            <div @click="useValue(item.value)" class="select-btn"></div>
                            <div @click="delRemind(item)" class="del-btn"></div>
                        </div>

                    </div>

                </template>

            </div>
            <div v-else>
                <div class="loading-block"> - 暂无常用描述短语 -</div>
            </div>
        </div>

        <at-modal title="添加常用描述短语" v-model="showAddModal" @ok="confirmAddRemind">
            <a-textarea v-model="remindValue" rows="4" :maxLength="130"/>
        </at-modal>

        <a-modal :footer="null" v-model="showPhotoModal" title="生活照">
            <div class="life-photo-container" v-if="lifePhotos.length">
                <template v-for=" (photo,pk) in lifePhotos ">
                    <a target="_blank" :href="photo" class="life-photo" :key="pk"
                       :style="`background-image:url(${photo})`"></a>
                </template>

                <a href="life-photo empty"></a>
                <a href="life-photo empty"></a>
                <a href="life-photo empty"></a>
            </div>
            <div v-else>
                <div class="loading-block"> - 暂无生活照 -</div>
            </div>
        </a-modal>

    </div>
</template>

<script>
import api from "@/repo/api";
import AtModal from "@/components/AtModal.vue";

export default {
    name: "ReportDetail",
    components: {AtModal},
    props: {
        reportDetail: Object,
        detail: Object,
        report: Object,
        member: Object
    },
    mounted() {
        if (this.detail) this.itemData = this.detail || {}

        this.getReminds()
        this.loadLifePhotos()
    },
    watch: {
        detail() {
            this.itemData = this.detail || {}
        },
        report() {
            this.loadLifePhotos()
        }
    },
    data() {
        return {
            itemData: {},
            remindKey: '',
            remindList: [],
            showAddModal: false,
            showPhotoModal: false,
            remindValue: '',
            remindTitle: '',
            mainRemindList: [],
            lifePhotos: [],
        }
    },
    methods: {
        loadLifePhotos() {

            this.lifePhotos = this.report.life_photos || []

            if (!this.report.member_image) return;
            if (!this.report.member_image.life_photos) return;
            if (!this.report.member_image.life_photos.length) return;

            this.lifePhotos = this.lifePhotos.concat(this.report.member_image.life_photos);
        },
        delRemind(item) {
            this.$confirm({
                title: '确定要删除这条常用描述短语？',
                onOk: () => {

                    this.$loading.show()

                    api.post('/ast-app/del-photo-report-remind', {
                        id: item.id
                    }, (data) => {

                        this.$loading.hide()

                        if (data.code === 0) {

                            let idx = this.mainRemindList.indexOf(item)
                            this.mainRemindList.splice(idx, 1)

                            this.showRemind(this.remindKey)
                        }

                    })

                }
            })
        },
        useValue(value) {
            let current = this.itemData[this.remindKey];

            if (current) value = current + ';' + value

            this.itemData[this.remindKey] = value
        },
        confirmAddRemind() {
            this.$loading.show()

            api.post('/ast-app/add-photo-report-remind', {
                key: this.remindKey,
                value: this.remindValue
            }, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {

                    this.showAddModal = false

                    this.mainRemindList.push(data.data)
                    this.showRemind(this.remindKey)
                }

            });
        },
        getReminds() {
            api.get('/ast-app/photo-report-reminds', (data) => {
                this.mainRemindList = data.data || []
                this.showRemind('')
            })
        },
        showRemind(key) {
            this.remindKey = key

            this.remindList = []

            let titles = {
                'gr_sq': '个人诉求',
                'zt_fg': '整体风格',
                'zt_sc': '整体色彩',
                'sc_tx': '身材体型',
                'zr_pf': '妆容皮肤',
                'fx_fs': '发型发色',
                'result': '初诊结论'
            };

            this.remindTitle = titles[key] || ''

            if (!this.remindKey) return;

            this.mainRemindList.forEach(item => {

                if (item.key === this.remindKey) this.remindList.push(item)

            })
        },
        activeLifePhotosModal() {
            this.showPhotoModal = true
        },
        activeRemindModal() {
            this.remindValue = '';
            this.showAddModal = true;
        },
        saveReport() {
            this.$loading.show()

            api.post('/ast-app/sync-photo-result', this.itemData, (data) => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code === 0) {

                    this.$router.push('/ast/members/reports');

                }

            });
        }
    }
}
</script>

<style scoped lang="less">

.pit {
    position: absolute;
    //background-color: red;
    left: 0;
    top: 0;
}

.p-height {
    left: 280px;
    top: 223px;
}

.p-grsq, .p-ztfg, .p-ztsc, .p-sctx, .p-res, .p-zrpf, .p-fxfs {
    top: 347px;
    left: 80px;
    width: 630px;
    height: 70px;
    overflow: hidden;
}

.p-fz-lg {
    left: 230px;
    top: 300px;
}

.p-mb-lk {
    left: 345px;
    top: 300px;
}

.p-mb-xt {
    left: 480px;
    top: 300px;
}


.p-mb-cd {
    left: 480px;
    top: 270px;
}

.p-mb-md {
    left: 345px;
    top: 270px;
}

.p-fs {
    left: 230px;
    top: 270px;
}

.p-zrpf, .p-fxfs {
    width: 275px;
    top: 700px;
}

.p-res {
    top: 790px;
}

.p-fxfs {
    left: 433px;
}

.p-ztsc {
    top: 523px;
}

.p-ztfg {
    top: 434px;
}

.p-sctx {
    top: 611px;
}

.p-weight {
    left: 423px;
    top: 223px;
}

.p-image {
    width: 133px;
    height: 160px;
    left: 582px;
    top: 171px;
    background-size: cover;
    background-position: center;
}

.p-mid {
    top: 180px;
    left: 260px;
}

.p-age {
    top: 180px;
    left: 535px;
}

.p-id {
    top: 118px;
    left: 640px;
}

.p-name {
    top: 180px;
    left: 100px;
}

.p-gender {
    top: 180px;
    left: 423px;
}

.p-content {
    position: relative;
    width: 755px;
}

.p-bg {
    position: relative;
    width: 100%;
    font-size: 18px;
}

.p-textarea {
    height: 100%;
}

.p-check-man {
    position: absolute;
    top: 940px;
    left: 24px;
    width: 400px;
}

.p-style {
    left: 90px;
    top: 272px;
    width: 80px;
    text-align: center;
    color: white;
}

.p-color {
    left: 90px;
    top: 300px;
    width: 80px;
    text-align: center;
    color: white;
}

.select-btn {
    width: 20px;
    height: 20px;
    background-image: url("/assets/icons/stock-selected.png");
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}

.del-btn {
    width: 20px;
    height: 20px;
    background-image: url("/assets/icons/close-btn-black.png");
    background-size: cover;
    cursor: pointer;
}

.remind {
    position: fixed;
    top: 128px;
    left: 1100px;
    background-color: #c2e6f6;
    width: 300px;
    height: 650px;
    border-left: 4px solid orange;
}

.remind-item {
    margin-top: 10px;
    padding: 5px;
    border-bottom: 1px dashed #666666;
    position: relative;

    &:hover .re-btn-group {
        opacity: 1;
    }
}

.re-btn-group {
    position: absolute;
    right: 0;
    display: flex;
    top: 5px;
    padding-right: 5px;
    opacity: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.life-photo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.life-photo {
    width: 200px;
    height: 200px;
    background-size: cover;

    &.empty {
        height: 0;
    }
}

.p-city {
    left: 100px;
    top: 223px;
}

.p-jobs {
    z-index: 2;
    left: 536px;
    top: 224px;
}

</style>