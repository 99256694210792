<template>
    <div>
        <div class="loading-block" v-if="loading">
            <a-spin :spinning="loading"></a-spin>
        </div>
        <div v-else>
            <report-editor
                    :report="report"
                    :report-detail="reportDetail"
                    :member="member"
                    :detail="resultData"
            ></report-editor>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";
import reportEditor from "@/views/AST/Member/Com/ReportEditor.vue";

export default {
    name: "ReportDetail",
    components: {
        reportEditor
    },
    mounted() {
        let photo_id = this.$route.query.photo_id

        if (photo_id) this.loadPhoto(photo_id)

        let id = this.$route.query.id

        if (id) this.loadResult(id)
    },
    data() {
        return {
            routeMatch: '/ast/members/reports',
            loading: true,
            report: {},
            reportDetail: {},
            member: {},
            resultData: {},
        }
    },
    methods: {
        loadResult(id) {
            api.get('/ast-app/photo-result', {id}, (data) => {

                if (data.code !== 0) {
                    return this.$message.error(data.msg)
                }

                this.report = data.data.report
                this.member = data.data.member
                this.reportDetail = data.data.report_detail

                let pd = data.data

                delete pd['member']
                delete pd['report']
                delete pd['report_detail']

                this.resultData = pd

                this.loading = false

            });
        },
        loadPhoto(id) {
            api.get('/ast-app/photo', {id}, (data) => {
                if (data.code !== 0) {
                    return this.$message.error(data.msg)
                }

                this.report = data.data
                this.member = data.data.member
                this.reportDetail = data.data.detail

                this.resultData = {
                    photo_id: data.data.id
                }

                this.loading = false
            })
        }
    }
}
</script>

<style scoped lang="less">

</style>